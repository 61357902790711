import profileTracking from '../profiles/profiles.tracking';
import kidslock from '../kidslock/kidslock';

function init() {
	// we have multiple user-dropdowns (one for mobile, one for desktop)
	const userDropdowns = document.querySelectorAll(
		'[js-module~="userDropdown"]'
	);
	if (!userDropdowns.length) return;

	userDropdowns.forEach((userDropdown) => {
		new UserDropdown(userDropdown);
	});
}

const UserDropdown = function (element) {
	let profilesAlreadyFetched = false;
	const userDropdownWrapper = element.querySelector(
		'[js-element~="userDropdownWrapper"]'
	);
	const fallback = element.querySelector(
		'[js-element~="userDropdownFallback"]'
	);
	const profileContainer = element.querySelector(
		'[js-element~="userDropdownProfilesContainer"]'
	);
	const subscriptionContainer = element.querySelector(
		'[js-element~="userDropdownSubscriptionPlaceholder"]'
	);
	let originalActiveNavElements;

	element.addEventListener('toggle', toggleHandler, { passive: true });

	function toggleHandler() {
		if (element.open) {
			originalActiveNavElements = element
				.closest('.nav')
				.querySelectorAll('.is-active');
			originalActiveNavElements.forEach((el) => {
				el.classList.remove('is-active');
			});
			element.parentElement.classList.add('is-active');
			// fetch the profiles
			// only for the first time
			if (!profilesAlreadyFetched && userDropdownWrapper.dataset.profileUrl) {
				getProfiles(userDropdownWrapper.dataset.profileUrl);
			}
			document.addEventListener('click', globalClose, { passive: true });
			document.addEventListener('keydown', globalClose, { passive: true });
		} else {
			element.parentElement.classList.remove('is-active');
			originalActiveNavElements.forEach(function (el) {
				el.classList.add('is-active');
			});
		}
	}

	async function getProfiles(profileUrl) {
		const response = await fetch(profileUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'text/xml',
				'X-Requested-With': 'XMLHttpRequest',
			},
		});

		if (!response.ok) {
			showError();
			return;
		}

		const content = await response.text();
		onSuccess(content);
	}

	function onSuccess(response) {
		if (fallback.classList.contains('is-visible')) {
			fallback.classList.remove('is-visible');
		}

		const responseHTML = new DOMParser().parseFromString(response, 'text/html');

		const profilesResponse = responseHTML.querySelector(
			'[js-element~="profilesResponse"]'
		);

		const subscriptionResponse = responseHTML.querySelector(
			'[js-element~="subscriptionResponse"]'
		);

		profileContainer.classList.remove('is-hidden');
		profileContainer.innerHTML = profilesResponse.innerHTML;

		if (subscriptionContainer && subscriptionResponse) {
			subscriptionContainer.innerHTML = subscriptionResponse.innerHTML;
			subscriptionContainer.classList.remove('user-dropdown__item--dummy');
		}

		// only once ;-)
		profilesAlreadyFetched = true;
		// attach eventlisteners for tracking and kidslock
		profileTracking.init();
		kidslock.init();
	}

	function showError() {
		if (!fallback.classList.contains('is-visible')) {
			fallback.classList.add('is-visible');
		}

		if (!profileContainer.classList.contains('is-hidden')) {
			profileContainer.classList.add('is-hidden');
		}
	}

	function globalClose(e) {
		const backdrop = element.querySelector(
			'[js-element~="userDropdownBackdrop"]'
		);

		if ((element.open && e.target === backdrop) || e.key === 'Escape') {
			element.open = false;
			element.parentElement.classList.remove('is-active');
			originalActiveNavElements.forEach(function (el) {
				el.classList.add('is-active');
			});

			document.removeEventListener('click', globalClose, { passive: true });
			document.removeEventListener('keydown', globalClose, { passive: true });
		}
	}
};

export default {
	init,
};
