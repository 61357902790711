import { debounce } from '../../utils/throttle/debounce';
import blockingOverlays from '../blocking-overlays/blocking-overlays';
import swimlane from '../swimlane/swimlane';
import mediaToggle from '../media-toggle/media-toggle';

// This module fetches the corresponding content via Fetch when
// you switch seasons
let swimlanes = undefined;

function init() {
	swimlanes = false;
	const seasonPickers = document.querySelectorAll(
		'[js-module~="seasonPicker"]'
	);

	if (!seasonPickers.length) {
		return;
	}

	window.dataLayer = window.dataLayer || [];

	if (
		document.querySelectorAll('x-swimlane[swimlane-type="episodes"]').length
	) {
		swimlanes = true;
	}

	seasonPickers.forEach((picker) => {
		const links = picker.querySelectorAll('[js-element~="seasonPickerLink"]');
		links.forEach((link) => {
			link.addEventListener('click', (e) => {
				seasonPicker(e);
				e.preventDefault();
			});
			link.addEventListener('keydown', (e) => {
				if (e.key === 'Enter' || e.key === ' ') {
					seasonPicker(e);
					e.preventDefault();
				}
			});
		});
	});

	// to set initial state
	window.history.replaceState(
		{
			activeUrl: window.location.href,
		},
		document.title,
		document.location.href
	);
}

async function seasonPicker(event) {
	const link = event.target;
	const newUrl = link.href || link.dataset.value; // the "link" can be an anchor or an option
	const container = document.querySelector('.detail__season-wrapper');
	const scrollBehavior =
		window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
		window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true
			? 'auto'
			: 'smooth';

	if (window.location.href.includes(newUrl)) {
		self.location.href = `#${window.i18n.episodes}`;
		return;
	}

	setActiveSeasonLink(link);

	onFetch();
	const response = await fetch(newUrl, {
		method: 'GET',
		headers: {
			'Content-Type': 'text/xml',
			'X-Requested-With': 'XMLHttpRequest',
		},
	});

	if (!response.ok) {
		showError();
		return;
	}
	const content = await response.text();

	debounce(onSuccess(link.dataset.seasonIndex, content), 150);

	window.history.pushState(
		{
			activeUrl: newUrl,
		},
		link.textContent,
		newUrl
	);

	// scroll to the TARGET so it highlights the correct ANCHOR in the menu
	if (link.hash.length > 0) {
		const destination = document.querySelector(link.hash);
		destination.scrollIntoView({
			behavior: scrollBehavior,
		});

		// set the focus on the first clickbale element in the destination
		destination.querySelector('.media a').focus();
	}

	function onSuccess(index, response) {
		container.classList.remove('is-loading');
		container.innerHTML = response;

		// execute any code that comes with the Fetch response
		const script = container.querySelector('script');
		if (script) {
			const code = script.innerHTML.replace(/[\t\n]+/gm, '');
			const newScript = document.createElement('script');
			newScript.nonce = script.nonce;
			newScript.innerHTML = code;
			container.appendChild(newScript);
			script.remove();
		}

		// reattach eventlisteners
		blockingOverlays.init();
		mediaToggle.init();

		if (swimlanes) {
			swimlane.switchEpisodeSwimlanes(
				document.querySelectorAll('x-swimlane[swimlane-type="episodes"]')
			);
		}

		window.dataLayer.push({
			event: 'select_detail',
			detail_menu_item: window.i18n.episodes,
			season_nr: index,
		});
	}

	function onFetch() {
		container.classList.add('is-loading');
	}

	function showError() {
		container.classList.remove('is-loading');
		container.innerHTML = window.i18n.seasonPickerError;
	}

	function setActiveSeasonLink(el) {
		const links = document.querySelectorAll('[js-element~="seasonPickerLink"]');

		links.forEach((link) => {
			link.removeAttribute('aria-current');
		});

		// update the season list and not the dropdown
		if (el.getAttribute('role') !== 'option') {
			el.setAttribute('aria-current', 'true');
		}

		// update the dropdown and the season list
		document
			.querySelectorAll('[js-element~="customSelectOption"]')
			.forEach((option) => {
				option.removeAttribute('aria-selected');
				if (option.dataset.seasonIndex === el.dataset.seasonIndex) {
					option.setAttribute('aria-selected', 'true');
				}
			});

		const dropdownValues = document.querySelectorAll(
			'[js-element~="seasonPickerValue"]'
		);
		dropdownValues.forEach((val) => {
			val.textContent = el.textContent;
		});
	}
}

export default {
	init,
};
